import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Document } from "@contentful/rich-text-types"
import styled from "styled-components"

const Container = styled.div`
  white-space: pre-line;
`

interface Props {
  node: {
    json: Document
  }
  className?: string
}

// https://www.contentful.com/developers/docs/tutorials/general/rich-text-and-gatsby/

const RichText: React.FC<Props> = ({ node, ...props }) => (
  <Container {...props}>{documentToReactComponents(node.json)}</Container>
)

export default RichText
